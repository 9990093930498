<template>
    <div class="page">
      <div class="entry entry_variants">
        <div class="entry__center center">
            <div class="entry__head">
            <a class="entry__logo" href="/"><img class="entry__pic entry__pic_white" src="img/logo.png" alt="Logo"><img class="entry__pic entry__pic_black" src="img/logo.png" alt="Logo"></a></div>
          <div class="entry__body">
            <div class="entry__row">
              <div class="entry__details">
                <div class="entry__title title title_sm">Ci stiamo ancora lavorando!</div>
                <div class="entry__info">Stiamo riprogettando il nostro sito web per una migliore esperienza utente.</div>
                <div class="entry__hand"><img class="entry__pic" src="img/entry-pic-1.png" alt=""></div>
              </div>
              <div class="entry__form">
                <div class="entry__title title title_sm">Cosa fare<span class="title__color">?</span></div>
                <div class="entry__btns"><a class="entry__btn btn btn_purple" href="/">Torna indietro!</a><button class="entry__btn btn btn_pink"><img class="btn__pic" src="img/instagram.png" alt=""><span class="btn__text">Seguici su Instagram</span></button></div>
                
              </div>
            </div>
          </div>
         
        </div>
        <div class="entry__bg"><img class="entry__pic" src="img/entry-circle.svg" alt=""></div>
        <div class="entry__cube"><img class="entry__pic" src="img/cube.png" alt=""></div>
        <div class="entry__circles">
          <div class="entry__circle"></div>
          <div class="entry__circle"></div>
          <div class="entry__circle"></div>
          <div class="entry__circle"></div>
          <div class="entry__circle"></div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Page404',
  data() {
    return {
      //
    }
  },
  methods: {
    //
  }
}
</script>